<template>
  <div>
    <h1 class="text-h4">{{ $lang.routes.templates }}</h1>
    <v-divider class="my-2"></v-divider>
    <v-row v-if="err || success" dense no-gutters class="px-3">
      <v-col v-if="err" cols="12">
        <v-alert color="error" :data-cy="err">{{ err }}</v-alert>
      </v-col>
      <v-col v-if="success" cols="12">
        <v-alert color="success" :data-cy="success">{{ success }}</v-alert>
      </v-col>
    </v-row>
    <v-row dense no-gutters>
      <v-col cols="12">
        <Table
          :key="tableKey"
          :items="templates"
          :loading="loading"
          @activeOnly="activeOnlyFunction($event)"
          @fetchTemplates="fetchTemplates($event)"
          @copyCreate="copyCreate($event)"
        ></Table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Table from './TemplatesDatatable'
import {
  getTextTemplateByIdUsingGET as getTemplate,
  getTextTemplatesUsingGET as getTemplates, getTextTemplateTranslationsUsingGET as getTranslations
} from '@/utils/api'

export default {
  components: {
    Table
  },
  data() {
    return {
      err: '',
      success: '',
      activeOnly: true,
      tableKey: 0,
      loading: false,
      templates: { items: [], meta: {} }
    }
  },
  computed: {},
  created() {
    if (this.$route.params && this.$route.params.message) {
      this.success = this.$route.params.message
      setTimeout(() => this.success = '', 5000)
    }
  },
  methods: {
    copyCreate(item) {
      getTemplate({ templateId: item.id })
        .then((res) => {
          const tempItem = res.data.data

          getTranslations({ templateId: item.id })
            .then((res2) => {
              const tempTranslations = res2.data.data.items.map((x) => {
                x.isEdit = true

                return x
              })

              delete tempItem.id
              delete tempItem.createdOn
              delete tempItem.modifiedOn
              delete tempItem.roles

              tempItem.name = `${item.name} - Copy - ${this.$options.filters.formatDateTime(new Date())}`

              this.$router.push({ name: 'templateCreate', params: { copy: JSON.stringify({ tempItem, tempTranslations }) } })
            })
            .catch((err) => {
              this.err = err
            })

        })
        .catch((err) => {
          this.err = err
        })
    },
    activeOnlyFunction(event) {
      this.activeOnly = event.activeOnly

      this.fetchTemplates(event.options)
    },
    fetchTemplates(options) {
      this.loading = true

      const obj = {}

      if (options) {
        if (options.options && options.options.itemsPerPage !== -1) {
          obj.page = options.resetPage ? 1 : options.options.page || 1
          obj.size = options.options.itemsPerPage || 25
        } else {
          obj.page = 1
          obj.size = 25
        }
      } else {
        obj.page = 1
        obj.size = 25
      }

      if (options?.options?.sortDesc && options.options.sortDesc.length > 0) obj.orderDirection = options.options.sortDesc[0] ? 'DESC' : 'ASC'

      if (options && options.options && options.options.sortBy && options.options.sortBy.length > 0) {
        obj.orderField = options.options.sortBy[0]
      }

      if (options && options.search && options.search.length > 1) {
        obj.name = options.search
      }

      if (this.activeOnly) obj.status = 'ACTIVE'

      getTemplates(obj)
        .then((res) => {
          this.templates = res.data.data
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          this.err = error
          setTimeout(() => this.err = '', 5000)
          console.log(error)
        })
    }
  }
}
</script>
